import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PostsList from "../components/PostsList"

const TechPage = () => {
  const { tech } = useStaticQuery(graphql`
    query TechPosts {
      tech: allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "tech" } } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            timeToRead
            fields {
              slug
            }
            frontmatter {
              category
              date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
              description
              title
              pinned
              tags
              image
            }
          }
        }
      }
    }
  `)

  const postList = tech.edges
  return (
    <Layout>
      <SEO title="Tecnologia" />
      <PostsList postList={postList} />
    </Layout>
  )
}

export default TechPage
